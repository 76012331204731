<template>
  <router-view></router-view>
</template>

<script>
import { onBeforeUnmount } from 'vue'
import { useStore } from 'vuex';

export default {
  components: {},
  setup() {
    const store = useStore()

    onBeforeUnmount(() => {
      store.commit('header/setTabs', '')
    })
    if (store.state.account.role === 'chief') {
      const tabs = [
        { label: 'tabs.profile', to: { name: 'Profile' } },
        { label: 'tabs.staff', to: { name: 'ProfileStaff' } },
      ]
      store.commit('header/setTabs', tabs)
    }
  }
}
</script>